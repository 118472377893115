import * as React from 'react';

import { useLocalization } from '@shared-ui/localization-context';
import { CDN_URL } from 'src/constants';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText, UitkHeading } from 'uitk-react-text';

const HowCampaignsWork = () => {
  const { formatText } = useLocalization();

  const paragraph1 = formatText('common.sideInfoSheets.howCampaignsWorkParagraph1');
  const paragraph2 = formatText('common.sideInfoSheets.howCampaignsWorkParagraph2');
  const paragraph3 = formatText('common.sideInfoSheets.howCampaignsWorkParagraph3');
  const paragraph4 = formatText('common.sideInfoSheets.howCampaignsWorkParagraph4');

  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkText>
        <span dangerouslySetInnerHTML={{ __html: paragraph1 }} />
      </UitkText>
      <UitkText>
        <span dangerouslySetInnerHTML={{ __html: paragraph2 }} />
      </UitkText>
      <UitkText>
        <span dangerouslySetInnerHTML={{ __html: paragraph3 }} />
      </UitkText>
      <UitkText>
        <span dangerouslySetInnerHTML={{ __html: paragraph4 }} />
      </UitkText>
      <UitkText>
        <UitkHeading>{formatText('common.sideInfoSheets.howCampaignsWorkExampleHeading')}</UitkHeading>
        {formatText('common.sideInfoSheets.howCampaignsWorkExampleParagraph')}
      </UitkText>
      <UitkFigure>
        <UitkImage src={`${CDN_URL}/images/sample_campaign.png`} />
      </UitkFigure>
    </UitkLayoutFlex>
  );
};

export default HowCampaignsWork;
