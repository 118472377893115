import * as React from 'react';

import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { UitkLayoutFlexItem, UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkPill, UitkPillContainer } from 'uitk-react-pill';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading } from 'uitk-react-text';

import { HelpAndSupportNavigationCard } from '../../../common/__generated__/api/types';

interface ICategoryNavigationCardProps {
  data: HelpAndSupportNavigationCard;
  onCategorySelect: (categoryId: string) => void;
  selectedCategoryId: string;
}
const CategoryNavigationCard: React.FC<ICategoryNavigationCardProps> = ({
  data,
  onCategorySelect,
  selectedCategoryId,
}) => {
  return (
    <UitkCard padded>
      <UitkSpacing margin={{ blockend: 'four' }}>
        <UitkLayoutFlex>
          <UitkCardContentSection padded>
            <UitkLayoutFlexItem>
              <UitkSpacing padding={{ blockend: 'three' }}>
                <UitkHeading size={5}>{data.subHeading}</UitkHeading>
              </UitkSpacing>
            </UitkLayoutFlexItem>
            <UitkPillContainer wrap>
              {data.cardCategories.map((category) => (
                <UitkPill
                  selected={category.id === selectedCategoryId}
                  id={category.id}
                  onChange={() => onCategorySelect(category.id)}
                  key={category.id}
                >
                  {category.label}
                </UitkPill>
              ))}
            </UitkPillContainer>
          </UitkCardContentSection>
        </UitkLayoutFlex>
      </UitkSpacing>
    </UitkCard>
  );
};

CategoryNavigationCard.displayName = 'Category navigation card';
export { CategoryNavigationCard };
