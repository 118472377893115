import * as React from 'react';

import { useLocalization } from '@shared-ui/localization-context';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText, UitkHeading } from 'uitk-react-text';

const PaymentProfileInfo = () => {
  const { formatText } = useLocalization();
  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkHeading>{formatText('common.sideInfoSheets.paymentProfileInfoHeading')}</UitkHeading>
      <UitkText>{formatText('common.sideInfoSheets.paymentProfileInfoText')}</UitkText>
      <UitkHeading>{formatText('common.sideInfoSheets.paymentProfileCurrencyInfoHeading')}</UitkHeading>
      <UitkText>{formatText('common.sideInfoSheets.paymentProfileCurrencyInfoText')}</UitkText>
    </UitkLayoutFlex>
  );
};

export default PaymentProfileInfo;
