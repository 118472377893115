import { AuthController } from '@controllers/auth-controller';
import { CAMPAIGN_WIZARD, PARTNER_ROLES } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class CampaignWizardController extends AuthController {
  public pageId = CAMPAIGN_WIZARD;
  public path = '/campaign-wizard';
  public routeName = 'campaign-wizard';
  public bundles = [];
  public pageTitle = 'controllers.campaignWizard';
  public isPartnerView = true;
  public accessRoles = [
    PARTNER_ROLES.ADVERTISER_ADMIN,
    PARTNER_ROLES.ADVERTISER_USER,
    PARTNER_ROLES.ADMIN, //deprecated, to be removed
  ];

  public component = codeSplit(
    () => import(/* webpackChunkName: "campaign-wizard" */ '../../views/partner/campaign-wizard'),
  );
  public exact = true;
}
