import { AuthController } from '@controllers/auth-controller';
import { PARTNER_ROLES } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class HelpAndSupportController extends AuthController {
  public pageId = 'helpAndSupport';
  public path = '/help';
  public routeName = 'helpAndSupport';
  public bundles = [];
  public pageTitle = 'controllers.helpAndSupport';
  public component = codeSplit(() => import(/* webpackChunkName: "helpAndSupport" */ '../../views/help-and-support'));
  public exact = true;
  public isPartnerView = true;
  public accessRoles = [
    PARTNER_ROLES.ADVERTISER_ADMIN,
    PARTNER_ROLES.ADVERTISER_USER,
    PARTNER_ROLES.ADVERTISER_VIEWER,
    PARTNER_ROLES.ADMIN, //deprecated, to be removed
  ];
  public readOnlyAccessRoles = [PARTNER_ROLES.ADVERTISER_VIEWER];
  public navIcon = 'help';
}
