import React, { useEffect, useState } from 'react';

import { UitkTertiaryButton } from '@egds/react-core';
import { useLocalization } from '@shared-ui/localization-context';
import { getCurrentDateTimeUTC } from '@utils/date-utils';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { UitkPrimaryButton } from 'uitk-react-button';
import { UitkCheckbox } from 'uitk-react-checkbox';
import { UitkDialog, UitkDialogContent } from 'uitk-react-dialog';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkParagraph } from 'uitk-react-text';

import { fetchTermsConditionsMetadata } from '../../utils/terms-conditions-util';

const TermsAndConditions: React.FC<{ onAccept: () => void }> = ({ onAccept }) => {
  const { formatText } = useLocalization();
  const [isChecked, setIsChecked] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [cdnTermsConditionsFileUrl, setCdnTermsConditionsFileUrl] = useState('');
  const [cdnTermsConditionsVersion, setCdnTermsConditionsVersion] = useState('');
  const [profileTermsConditions, setProfileTermsConditions] = useState(null);
  const history = useHistory();

  // Flags to track if the profile extension has been synced with the local storage
  let hadSyncedTermsConditionsProfileExtensionWithLocalStorage = false;

  useEffect(() => {
    const getMetadata = async () => {
      try {
        const cdnTermsConditons = await fetchTermsConditionsMetadata();
        setCdnTermsConditionsVersion(cdnTermsConditons.version);
        setCdnTermsConditionsFileUrl(cdnTermsConditons.fileUrl);

        const profileTermsConditions = await handleProfileTermsConditionsCheck(cdnTermsConditons.version);
        setProfileTermsConditions(profileTermsConditions);
        const profileVersionNotMatchCdn =
          !profileTermsConditionsMatchesCdnVersion(profileTermsConditions, cdnTermsConditons.version) &&
          !hadSyncedTermsConditionsProfileExtensionWithLocalStorage;

        if (profileVersionNotMatchCdn) {
          setShowDialog(true);
        }
      } catch (error) {
        console.error("Error fetching the user's Terms and Conditions data:", error);
      }
    };
    getMetadata();
  }, []);

  const handleLogout = () => {
    history.push('/logout');
  };

  const handleProfileTermsConditionsCheck = async (cdnVersion) => {
    try {
      const result = await axios.get('/api/get-terms-conditions-acceptance', {});
      const timestamp = getCurrentDateTimeUTC();

      if (result.data && result.data.version != '') {
        const profileVersion = result.data.version;
        if (profileVersion !== cdnVersion) {
          await Promise.all([
            patchAcceptTermsConditions('/version', cdnVersion),
            patchAcceptTermsConditions('/timestamp', timestamp),
          ]);
          hadSyncedTermsConditionsProfileExtensionWithLocalStorage = true;
        }
      }

      if (hadSyncedTermsConditionsProfileExtensionWithLocalStorage) {
        return (await axios.get('/api/get-terms-conditions-acceptance', {})).data;
      } else {
        return result['data'];
      }
    } catch (error) {
      console.error('Error in handleProfileTermsConditionsCheck:', error);
    }
  };

  // Accept terms and conditions
  // This function is called when the user accepts the terms and conditions
  // It sends a POST request to the server to update the user's profile with the new terms and conditions
  // It also updates the local storage with the new terms and conditions version and timestamp
  const handleAcceptTermsConditions = async (versionInput, utcTimestampInput) => {
    const utcTimestamp = utcTimestampInput ?? getCurrentDateTimeUTC();
    try {
      //if there is an existing profile version, call patch
      if (profileTermsConditions != null && profileTermsConditions != '') {
        await Promise.all([
          patchAcceptTermsConditions('/version', versionInput),
          patchAcceptTermsConditions('/timestamp', utcTimestamp),
        ]);
      } else {
        await axios.post('/api/accept-terms-conditions', {
          timestamp: utcTimestamp,
          version: versionInput,
        });
      }
      localStorage.setItem('capTermsAndConditionsAccepted', versionInput);
      localStorage.setItem('capTermsAndConditionsAcceptedUtcTimestamp', utcTimestamp);
      onAccept();
    } catch (error) {
      console.error('Error in handleAcceptTermsConditions:', error);
    }
  };

  // Patch accept terms and conditions
  // This function is called when the user has already accepted the terms and conditions
  // It sends a PATCH request to the server to update the user's profile with the new terms and conditions
  const patchAcceptTermsConditions = async (pathInput, patchValue) => {
    try {
      await axios.patch('/api/patch-terms-conditions', {
        path: pathInput,
        value: patchValue,
      });
    } catch (error) {
      console.error('Error in patchAcceptTermsConditions:', error);
    }
  };

  const profileTermsConditionsMatchesCdnVersion = (currentProfileAcceptedTermsConditions, cdnVersion) => {
    return (
      currentProfileAcceptedTermsConditions != null && currentProfileAcceptedTermsConditions['version'] === cdnVersion
    );
  };

  if (!showDialog) return null;

  const tncLinkContext = formatText('termsAndConditions.cardLinkText', cdnTermsConditionsFileUrl);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <UitkDialog sheetType="centered" heightAuto dialogShow closeCallback={() => {}}>
        <UitkDialogContent>
          <UitkSpacing>
            <UitkHeading>{formatText('termsAndConditions.cardHeading')}</UitkHeading>
          </UitkSpacing>
          <UitkSpacing padding={{ blockend: 'two' }}>
            <UitkParagraph>
              <span dangerouslySetInnerHTML={{ __html: tncLinkContext }} />
              {formatText('termsAndConditions.cardLinkText')}
            </UitkParagraph>
          </UitkSpacing>
          <UitkLayoutFlex alignItems="center">
            <UitkCheckbox
              id="terms-and-conditions-checkbox"
              checked={isChecked}
              label={formatText('termsAndConditions.cardCheckboxText')}
              onChange={(e) => setIsChecked(e.target.checked)}
            >
              {formatText('termsAndConditions.cardCheckboxText')}
            </UitkCheckbox>
          </UitkLayoutFlex>
          <UitkSpacing margin={{ block: 'two' }}>
            <UitkPrimaryButton
              onClick={() => handleAcceptTermsConditions(cdnTermsConditionsVersion, getCurrentDateTimeUTC())}
              disabled={!isChecked}
              isFullWidth
            >
              {formatText('termsAndConditions.cardAcceptButtonText')}
            </UitkPrimaryButton>
          </UitkSpacing>
          <UitkSpacing margin={{ blockstart: 'one' }}>
            <UitkTertiaryButton onClick={handleLogout} isFullWidth>
              {formatText('termsAndConditions.cardLogoutButtonText')}
            </UitkTertiaryButton>
          </UitkSpacing>
        </UitkDialogContent>
      </UitkDialog>
    </div>
  );
};

export default TermsAndConditions;
