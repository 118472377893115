import React from 'react';

import { useLocalization } from '@shared-ui/localization-context';
import { UitkPagingNextButton, UitkPagingPrevButton } from 'uitk-react-button';
import { UitkCard } from 'uitk-react-cards';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText } from 'uitk-react-text';

interface IOverviewTablePaginationProps {
  page: number;
  totalPages: number;
  handlePrevious: () => void;
  handleNext: () => void;
  curDisplayedData: number;
  numDisplayed: number;
  tableRowsLength: number;
  pageSize: number;
}

export const OverviewTablePagination: React.FC<IOverviewTablePaginationProps> = ({
  page,
  totalPages,
  handlePrevious,
  handleNext,
  curDisplayedData,
  numDisplayed,
  tableRowsLength,
  pageSize,
}) => {
  const { formatText } = useLocalization();

  return (
    <div>
      <UitkCard padded overflow={true} className={'overview-container'}>
        <UitkLayoutFlex alignItems="center" space="twelve" justifyContent="end">
          <UitkPagingPrevButton role="previousbutton" onClick={handlePrevious} disabled={page === 1} />
          <UitkText size={300}>
            {formatText('campaignOverview.tablePaginationResultsText', {
              start: curDisplayedData,
              end: numDisplayed,
              total: tableRowsLength,
            })}
          </UitkText>
          <UitkPagingNextButton
            role="nextbutton"
            onClick={handleNext}
            disabled={page === totalPages || tableRowsLength < pageSize}
          />
        </UitkLayoutFlex>
      </UitkCard>
    </div>
  );
};
