import React from 'react';

import { useLocalization } from '@shared-ui/localization-context';
import { UitkPrimaryButton } from 'uitk-react-button';
import { UitkCard, UitkCardBackgroundTheme } from 'uitk-react-cards';
import { UitkIcon } from 'uitk-react-icons';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';

export const ErrorCard: React.FC<{
  error: Error;
  theme: UitkCardBackgroundTheme;
  message?: string;
  reloadClick?: () => void;
}> = (props) => {
  const { error, message, theme } = props;
  const { formatText } = useLocalization();
  return (
    <UitkCard backgroundTheme={theme}>
      <UitkSpacing margin={{ blockstart: 'twelve', blockend: 'three' }} padding={{ inline: 'six' }}>
        {message ? (
          <UitkHeading tag="h6" size={6}>
            {message}
          </UitkHeading>
        ) : (
          <>
            {error.message && (
              <UitkHeading tag="h6" size={6}>
                [{error?.message}]
              </UitkHeading>
            )}
          </>
        )}
      </UitkSpacing>
      <UitkSpacing padding={{ inline: 'six' }}>
        <UitkText>
          {formatText('common.errorCardMessage1')}
          <br />
          {formatText('common.errorCardMessage2')}
        </UitkText>
      </UitkSpacing>
      <UitkSpacing margin={{ inlinestart: 'six', block: 'three' }}>
        <UitkPrimaryButton
          type="button"
          onClick={props.reloadClick ? () => props.reloadClick() : () => window.location.reload()}
        >
          {formatText('common.errorCardRefreshText')}
          <UitkIcon name="refresh" />
        </UitkPrimaryButton>
      </UitkSpacing>
    </UitkCard>
  );
};
