import { ADMIN_ROLES } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

import { AuthController } from '../auth-controller';

export class AdCopyReviewController extends AuthController {
  public pageId = 'copyReview';
  public path = '/admn/copyReview';
  public routeName = 'adCopyReview';
  public bundles = [];
  public pageTitle = 'controllers.adCopyReview';
  public isAdminView = true;
  public accessRoles = [
    ADMIN_ROLES.INTERNAL_ADMIN,
    ADMIN_ROLES.INTERNAL_COPY_REVIEWER,
    ADMIN_ROLES.INTERNAL_TECH_SUPPORT,
    ADMIN_ROLES.INTERNAL_ADMIN_VIEWER,
    ADMIN_ROLES.INTERNAL_REV_OPS,
    ADMIN_ROLES.INTERNAL_SALES,
  ];
  public readOnlyAccessRoles = [
    ADMIN_ROLES.INTERNAL_ADMIN_VIEWER,
    ADMIN_ROLES.INTERNAL_REV_OPS,
    ADMIN_ROLES.INTERNAL_SALES,
  ];
  public navIcon = 'mode_edit';

  public component = codeSplit(() => import(/* webpackChunkName: "adCopyReview" */ '../../views/admin/ad-copy-review'));
  public exact = true;
}
