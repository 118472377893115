import * as React from 'react';

import { useLocalization } from '@shared-ui/localization-context';
import { CDN_URL } from 'src/constants';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText, UitkHeading } from 'uitk-react-text';

const PartnerAccountInfo = () => {
  const { formatText } = useLocalization();
  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkHeading>{formatText('common.sideInfoSheets.partnerAccountInfoHeading')}</UitkHeading>
      <UitkText>{formatText('common.sideInfoSheets.partnerAccountInfoText')}</UitkText>
      <UitkFigure imageFit="contain" style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>
        <UitkImage src={`${CDN_URL}/images/partner_account_info_beta.png`} />
      </UitkFigure>
      <UitkHeading>{formatText('common.sideInfoSheets.partnerAccountPropertiesInfoHeading')}</UitkHeading>
      <UitkText>{formatText('common.sideInfoSheets.partnerAccountPropertiesInfoText')}</UitkText>
      <UitkHeading>{formatText('common.sideInfoSheets.partnerAccountPaymentProfileInfoHeading')}</UitkHeading>
      <UitkText>{formatText('common.sideInfoSheets.partnerAccountPaymentProfileInfoText')}</UitkText>
      <UitkHeading>{formatText('common.sideInfoSheets.partnerAccountUsersInfoHeading')}</UitkHeading>
      <UitkText>{formatText('common.sideInfoSheets.partnerAccountUsersInfoText')}</UitkText>
    </UitkLayoutFlex>
  );
};

export default PartnerAccountInfo;
