import { AuthController } from '@controllers/auth-controller';
import { BILLING_CENTER, PARTNER_ROLES } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class InvoicesController extends AuthController {
  public pageId = BILLING_CENTER;
  public path = '/invoices';
  public routeName = 'invoices';
  public bundles = [];
  public pageTitle = 'controllers.invoices';
  public isPartnerView = true;
  public accessRoles = [PARTNER_ROLES.ADVERTISER_ADMIN];
  public navIcon = 'payments';

  public component = codeSplit(() => import(/* webpackChunkName: "invoices" */ '../../views/partner/invoices'));
  public exact = true;
}
