import * as React from 'react';

const useClickOutside = (ref, callback, escapeKey = false) => {
  const handleClick = (e) => {
    const target = e.target as Node;
    if (ref.current && !ref.current.contains(target)) {
      callback();
    }
  };
  const handleKeyPress = (e) => {
    if (escapeKey && e.key === 'Escape') {
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('keydown', handleKeyPress);
    };
  });
};

export default useClickOutside;
