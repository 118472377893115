import { gql } from '@apollo/client';

export const CAMPAIGN_MUTATION = gql`
  mutation ($context: ContextInput!, $productContext: ProductContext!, $campaignRequestInput: CampaignRequestInput!) {
    publishCampaign(context: $context, productContext: $productContext, campaignRequestInput: $campaignRequestInput) {
      errorMessage
      successMessageHeading
      successMessageDescription
    }
  }
`;

export const UPDATE_STATUS_MUTATION = gql`
  mutation UpdateCampaignStatusMutation(
    $context: ContextInput!
    $campaignId: String!
    $campaignIsPaused: Boolean
    $campaignName: String
    $adGroupId: String
    $adGroupIsPaused: Boolean
    $adGroupName: String
    $adId: String
    $adIsPaused: Boolean
    $adName: String
  ) {
    updateCampaignStatus(
      context: $context
      campaignId: $campaignId
      campaignIsPaused: $campaignIsPaused
      campaignName: $campaignName
      adGroupId: $adGroupId
      adGroupIsPaused: $adGroupIsPaused
      adGroupName: $adGroupName
      adId: $adId
      adIsPaused: $adIsPaused
      adName: $adName
    ) {
      ad {
        adId
        adType
        adName
        contentId
        isPaused
      }
      adGroup {
        adGroupId
        adGroupName
        budgetId
        campaignId
        isPaused
      }
      campaign {
        campaignId
        campaignName
        isPaused
      }
    }
  }
`;

export const APPROVE_AD_COPY = gql`
  mutation AdCopyApproval(
    $context: ContextInput!
    $productContext: ProductContext!
    $contentId: String!
    $flow: AdCopyHandleFlow!
  ) {
    adCopyAction(context: $context, productContext: $productContext, contentId: $contentId, flow: $flow) {
      responseStatus
      message
    }
  }
`;

export const REJECT_AD_COPY = gql`
  mutation AdCopyRejection(
    $context: ContextInput!
    $productContext: ProductContext!
    $contentId: String!
    $flow: AdCopyHandleFlow!
    $headlineDenyReason: DenyReasonInput
    $descriptionDenyReason: DenyReasonInput
  ) {
    adCopyAction(
      context: $context
      productContext: $productContext
      contentId: $contentId
      flow: $flow
      headlineDenyReason: $headlineDenyReason
      descriptionDenyReason: $descriptionDenyReason
    ) {
      responseStatus
      message
    }
  }
`;

export const APPLY_OPPORTUNITY_MUTATION = gql`
  mutation ApplyOpportunityMutation($context: ContextInput!, $applyOpportunityReq: ApplyOpportunityInput!) {
    applyOpportunity(context: $context, applyOpportunityReq: $applyOpportunityReq) {
      successResponse {
        icon {
          token
          description
          id
        }
        campaignDisplayName
        heading
        description
        acknowledgeButton {
          primary
        }
      }
      errorResponse {
        errorBanner {
          heading
          message
          theme
          icon {
            token
            description
            id
          }
        }
      }
    }
  }
`;

export const DISMISS_OPPORTUNITY_MUTATION = gql`
  mutation DismissOpportunityMutation($context: ContextInput!, $dismissOpportunityReq: DismissOpportunityInput!) {
    dismissOpportunity(context: $context, dismissOpportunityReq: $dismissOpportunityReq) {
      successMessage
      errorMessage
    }
  }
`;

export const ADD_PRODUCT_MUTATION = gql`
  mutation (
    $context: ContextInput!
    $operation: ProductAssociationOperation!
    $productAssociationCriteria: AssociatedAdvertiserPortalProductUpdateInput!
    $productContext: ProductContext!
  ) {
    advertiserPortalProductAssociation(
      context: $context
      operation: $operation
      productAssociationCriteria: $productAssociationCriteria
      productContext: $productContext
    ) {
      message
      responseStatus
    }
  }
`;

export const CREATE_SALESFORCE_CASE_MUTATION = gql`
  mutation CreateSalesforceCaseMutation($context: ContextInput!, $inputForm: SalesforceCaseInputModelInput!) {
    createSalesforceCase(context: $context, inputForm: $inputForm) {
      successResponse {
        message
        caseId
        referenceId
      }
      errorResponse {
        message
        errorMessage
      }
    }
  }
`;
