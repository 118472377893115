import { L10nModule } from 'bernie-l10n';

export const localization = (locale: string): L10nModule => {
  const mod = new L10nModule();

  mod.current = locale;
  try {
    mod.globalize = require(`advertiser-portal-pwa-localization/dist/bundle-${locale}`);
  } catch (e) {
    console.error(`Failed to load localization for locale: ${locale}`);
    mod.globalize = require('advertiser-portal-pwa-localization/dist/bundle-en_US');
  }

  return mod;
};
