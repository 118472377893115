import * as React from 'react';

import { useLocalization } from '@shared-ui/localization-context';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText } from 'uitk-react-text';

const LetUsChooseYourImage = () => {
  const { formatText } = useLocalization();

  const paragraph2 = formatText('common.sideInfoSheets.letUsChooseYourImageParagraph2');

  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkText>{formatText('common.sideInfoSheets.letUsChooseYourImageParagraph1')}</UitkText>
      <UitkText>
        <span dangerouslySetInnerHTML={{ __html: paragraph2 }} />
      </UitkText>
      <UitkText>{formatText('common.sideInfoSheets.letUsChooseYourImageParagraph3')}</UitkText>
    </UitkLayoutFlex>
  );
};

export default LetUsChooseYourImage;
