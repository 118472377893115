import React from 'react';

import { useStores } from '@hooks/use-stores';
import { useLocalization } from '@shared-ui/localization-context';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom-v5-compat';
import { UitkCard } from 'uitk-react-cards';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkText } from 'uitk-react-text';

const Banner: React.FC = () => {
  const { banner: bannerStore } = useStores();
  const { formatText } = useLocalization();
  const onDismiss = () => {
    bannerStore.clearMessages();
  };

  const location = useLocation();
  const pathname = location.pathname;

  // todo, handle multiple?
  const bannerToShow = bannerStore.banners.find((banner) => new RegExp(banner.regex).test(pathname));

  if (!bannerToShow) {
    return null;
  }

  return (
    <>
      <UitkCard padded style={{ height: '90px' }}>
        <UitkLayoutFlex space={'three'} blockSize={'full_size'} alignItems={'center'}>
          <UitkLayoutFlexItem>
            {bannerToShow.type === 'error' && <UitkIcon name={'error'} spotlight={'warning'} />}
            {bannerToShow.type === 'success' && <UitkIcon name={'check'} spotlight={'positive'} />}
          </UitkLayoutFlexItem>
          <UitkLayoutFlex direction={'column'} alignItems={'start'}>
            <UitkLayoutFlexItem>
              <UitkText weight={'bold'}>{bannerToShow.headline}</UitkText>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem>
              <UitkText>{bannerToShow.message}</UitkText>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem style={{ marginTop: '8px' }}>
              <UitkLink>
                <button onClick={onDismiss}>{formatText('common.dismissButtonText')}</button>
              </UitkLink>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkLayoutFlex>
      </UitkCard>
    </>
  );
};

export default observer(Banner);
