import { Buffer } from 'buffer';

import { Request as HapiRequest } from '@hapi/hapi';
import { generateTraceId, getTraceId } from '@utils/trace-utils';
import axios from 'axios';
import { getConfig } from 'bernie-config';
import { Request as BernieRequest } from 'bernie-http';
import qs from 'qs';
import { serializeError } from 'serialize-error';
import { ERROR, JSON_HEADERS, TRACE_ID } from 'src/constants';

const SERVICE_NAME = 'IDENTITY_TOKEN_SERVICE';

export interface PrincipalUserData {
  ver: string;
  sub: string;
  partner_account_id: string;
  iss: string;
  client_id: string;
  aud: string;
  acr: string[];
  nbf: number;
  idp: number;
  scope: string;
  auth_time: number;
  session_exp: number;
  actor_id: string;
  exp: number;
  iat: number;
  client_name: string;
  jti: string;
}

export interface IPrincipalResponse {
  encodedJwt: string;
}

const getApiClientOpaqueToken = async (
  request,
  clientId: string,
  clientSecret: string,
  traceId: string,
): Promise<string> => {
  const getBasicAuthHeader = (clientId: string, clientSecret: string): string => {
    const credentials = `${clientId}:${clientSecret}`;
    const encodedCredentials = Buffer.from(credentials).toString('base64');
    return `Basic ${encodedCredentials}`;
  };

  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getApiClientOpaqueToken', traceId]);

  const {
    services: { identityAuthService },
  } = getConfig();

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
    'Trace-ID': traceId,
    Authorization: getBasicAuthHeader(clientId, clientSecret),
  };

  const data = qs.stringify({
    grant_type: 'password',
    scope: 'openid',
  });

  try {
    const response = await axios({
      method: 'POST',
      url: `${identityAuthService.protocol}//${identityAuthService.hostname}/api/v3/token`,
      signal: AbortSignal.timeout(5000),
      data,
      headers,
    });
    return response.data['access_token'];
  } catch (error) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getApiClientOpaqueToken', traceId], {
      error: serializeError(error),
    });
    throw error;
  }
};

const getApiClientAccessToken = async (
  request,
  clientId: string,
  clientSecret: string,
): Promise<IPrincipalResponse> => {
  const traceId = generateTraceId();

  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getApiClientAccessToken', traceId]);

  const headers = {
    Authorization: `Basic ${Buffer.from(`${clientId}:${clientSecret}`).toString('base64')}`,
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/x-www-form-urlencoded',
    [TRACE_ID]: traceId,
  };

  const {
    services: { identityAuthService },
  } = getConfig();

  const data = qs.stringify({
    grant_type: 'client_credentials',
  });

  try {
    const response = await axios({
      method: 'POST',
      url: `${identityAuthService.protocol}//${identityAuthService.hostname}/api/v1/token?provider=eg-identity&format=jwt-principal`,
      data,
      signal: AbortSignal.timeout(5000),
      headers,
    });

    return response.data;
  } catch (error) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getApiClientAccessToken', traceId], {
      error: serializeError(error),
    });
    throw error;
  }
};

const getApiClientPrincipalToken = async (
  request,
  clientId: string,
  clientSecret: string,
): Promise<IPrincipalResponse> => {
  const traceId = generateTraceId();
  const opaqueToken = await getApiClientOpaqueToken(request, clientId, clientSecret, traceId);

  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getApiClientPrincipalToken', traceId]);

  const {
    services: { identityTokenService },
  } = getConfig();

  try {
    const response = await axios({
      method: 'POST',
      url: `${identityTokenService.protocol}//${identityTokenService.hostname}/v3/tokens/exchange`,
      signal: AbortSignal.timeout(5000),
      data: {
        opaqueToken,
      },
      headers: {
        ...JSON_HEADERS,
        [TRACE_ID]: traceId,
      },
    });

    return response.data;
  } catch (error) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getApiClientPrincipalToken', traceId], {
      error: serializeError(error),
    });
    throw error;
  }
};

const getOpaqueToken = (request: BernieRequest | HapiRequest): string | undefined => {
  const cookies = request.headers.cookie.split('; ');
  const sessionToken = cookies?.find((row: string) => row.startsWith('EG_SESSIONTOKEN='));
  return sessionToken ? sessionToken.split('=')[1] : sessionToken;
};

const getPrincipalToken: (request) => Promise<IPrincipalResponse & Error> = async (request) => {
  const traceId = getTraceId(request);
  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getPrincipalToken', traceId]);

  const opaqueToken = getOpaqueToken(request);
  if (!opaqueToken) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getPrincipalToken', traceId], {
      message: 'no opaque token',
    });
    throw new Error('Unauthorized');
  }

  const {
    services: { identityTokenService },
  } = getConfig();

  const { protocol, hostname } = identityTokenService;

  try {
    const response = await axios({
      method: 'POST',
      url: `${protocol}//${hostname}/v3/tokens/exchange`,
      signal: AbortSignal.timeout(5000),
      data: {
        opaqueToken,
      },
      headers: {
        ...JSON_HEADERS,
        [TRACE_ID]: traceId,
      },
    });

    return response.data;
  } catch (error) {
    request.log([ERROR, SERVICE_NAME, 'advertiser-portal-pwa.error.getPrincipalToken', traceId], {
      error: serializeError(error),
    });
    throw error;
  }
};

const getJwtFromToken: (token: string) => PrincipalUserData = (token) => {
  const principalUser = Buffer.from(token.split('.')[1], 'base64').toString('utf-8');
  return JSON.parse(principalUser);
};

export {
  getApiClientOpaqueToken,
  getApiClientAccessToken,
  getApiClientPrincipalToken,
  getOpaqueToken,
  getPrincipalToken,
  getJwtFromToken,
};
