import { ADMIN_ROLES } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

import { AuthController } from '../auth-controller';

export class RejectAdCopyController extends AuthController {
  public pageId = 'rejectAdCopy';
  public path = '/admn/copyReview/reject-ad-copy';
  public routeName = 'rejectAdCopy';
  public bundles = [];
  public pageTitle = 'controllers.rejectAdCopy';
  public isAdminView = true;
  public accessRoles = [
    ADMIN_ROLES.INTERNAL_ADMIN,
    ADMIN_ROLES.INTERNAL_COPY_REVIEWER,
    ADMIN_ROLES.INTERNAL_TECH_SUPPORT,
  ];

  public component = codeSplit(() => import(/* webpackChunkName: "rejectAdCopy" */ '../../views/admin/reject-ad-copy'));
  public exact = true;
}
