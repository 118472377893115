import * as React from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText } from 'uitk-react-text';

// TODO dont think this is used
const HowAdGroupsWork = () => {
  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkText>
        To advertise on Expedia Group sites, you need three things: a <b>campaign</b>, an <b>ad group</b>, an{' '}
        <b>ad group </b> and an <b>ad</b>
      </UitkText>
      <UitkText>
        <b>Campaigns</b> give your advertising structure and allow you to filter or run performance reports across
        multiple ads. You can create as many campaigns as you like.
      </UitkText>
      <UitkText>
        Under each ad group is your <b>ad</b>, which is what travelers see in search results. Putting ads under ad
        groups helps you create multiple ads with the same targeting. You can create up to 11 ads per ad group (one per
        language).
      </UitkText>
    </UitkLayoutFlex>
  );
};

export default HowAdGroupsWork;
