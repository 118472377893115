import * as React from 'react';
import { useState, useEffect, useRef } from 'react';

import { gql, useQuery } from '@apollo/client';
import { HowToArticle } from '@components/helpAndSupport/fragments/how-to-article';
import { CategoryNavigationCard } from '@components/helpAndSupport/internal/category-navigation-card';
import { SubCategoryCard } from '@components/helpAndSupport/internal/sub-category-card';
import { QueryComponent } from '@graphql/component-types';
import { useBexApi } from '@shared-ui/bex-api-context';
import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkLineSkeleton } from 'uitk-react-skeleton';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading } from 'uitk-react-text';
import { UitkToast } from 'uitk-react-toast';

import { IntakeForm } from './internal/intake-form';
import { SubCategory, SubSubCategory } from '../../common/__generated__/api/types';

export const HELP_SUPPORT_NAV_QUERY = gql`
  query helpAndSupportNavigation($context: ContextInput!) {
    helpAndSupportWizard {
      helpAndSupportNavigation(context: $context) {
        heading
        subHeading
        cardCategories {
          label
          id
          subCategory {
            ...subCategoryFragment
          }
        }
      }
    }
  }
  ${SubCategoryCard.fragment}
`;

interface Inputs {}

const HelpAndSupport: QueryComponent<Inputs, Inputs> = () => {
  const { context } = useBexApi();
  const { data, loading, error } = useQuery(HELP_SUPPORT_NAV_QUERY, {
    variables: {
      context: context,
    },
  });
  const [selectedCategoryId, setSelectedCategoryId] = useState('getting_started');
  const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategory | null>(null);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState<SubSubCategory | null>(null);
  const [successResponse, setSuccessResponse] = useState('');
  const [showToast, setShowToast] = useState(false);
  const onCloseRef = useRef(null);
  const renderToast = (successMsg) => {
    setShowToast(true);
    setSuccessResponse(successMsg);
  };
  useEffect(() => {
    if (showToast) {
      setSelectedSubSubCategory(null);
      setSelectedSubCategory(null);
      const timer = setTimeout(() => {
        setShowToast(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  if (loading) {
    return (
      <>
        <UitkCard padded>
          <UitkCardContentSection>
            <UitkLineSkeleton />
          </UitkCardContentSection>
          <UitkCardContentSection>
            <UitkLineSkeleton animation={true} type="primary" />
          </UitkCardContentSection>
        </UitkCard>
      </>
    );
  }

  if (error) {
    return <div>Error loading data</div>;
  }

  const navigationSection = data?.helpAndSupportWizard.helpAndSupportNavigation;
  const handleCategorySelect = (categoryId: string) => {
    setSelectedCategoryId(categoryId);
    setSelectedSubCategory(null);
    setSelectedSubSubCategory(null);
  };

  const selectedCategory = navigationSection.cardCategories.find((category) => category.id === selectedCategoryId);
  if (!data || !navigationSection) {
    return null;
  }

  const chunkArray = (array: SubCategory[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleSubSubCategorySelect = (subSubCategory: SubSubCategory, subCategory: SubCategory) => {
    setSelectedSubSubCategory(subSubCategory);
    setSelectedSubCategory(subCategory);
  };

  return (
    <>
      <UitkSpacing margin={{ blockend: 'four' }}>
        <UitkHeading size={4}>{navigationSection.heading}</UitkHeading>
      </UitkSpacing>
      <UitkToast onCloseRef={onCloseRef} show={showToast} autoHide={false}>
        {successResponse}
      </UitkToast>
      <CategoryNavigationCard
        data={navigationSection}
        onCategorySelect={handleCategorySelect}
        selectedCategoryId={selectedCategoryId}
      />
      {selectedCategory && (
        <>
          {selectedSubSubCategory ? (
            selectedSubSubCategory.answer ? (
              // Render HowToArticle when selectedSubSubCategory has an answer
              <HowToArticle
                data={selectedSubSubCategory}
                parentCategory={selectedCategory.label}
                subCategory={selectedSubCategory.name}
                onBack={() => {
                  setSelectedSubSubCategory(null);
                  setSelectedSubCategory(null);
                }}
              />
            ) : (
              // Render ContactForm when selectedSubSubCategory has no answer
              <IntakeForm
                data={selectedSubSubCategory}
                parentCategory={selectedCategory.label}
                subCategory={selectedSubCategory.name}
                question={selectedSubSubCategory.question}
                onBack={() => {
                  setSelectedSubSubCategory(null);
                  setSelectedSubCategory(null);
                }}
                renderToast={renderToast}
              />
            )
          ) : (
            // Render SubCategoryCards when no subSubCategory is selected
            <>
              {chunkArray(selectedCategory.subCategory, 2).map((subCatChunk, index) => (
                <UitkSpacing key={index} margin={{ blockend: 'four' }}>
                  <UitkLayoutFlex direction="row" space="four">
                    {subCatChunk.map((subCat) => (
                      <SubCategoryCard
                        key={subCat.name}
                        data={subCat}
                        onSubSubCategorySelect={handleSubSubCategorySelect}
                      />
                    ))}
                  </UitkLayoutFlex>
                </UitkSpacing>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

HelpAndSupport.displayName = 'Help and support';
export { HelpAndSupport };
