import React from 'react';

import Banner from '@common/banner';
import HowAdGroupsWork from '@common/sideInfoSheets/how-ad-groups-work';
import HowBudgetingWorks from '@common/sideInfoSheets/how-budgeting-works';
import HowCampaignGoalsWork from '@common/sideInfoSheets/how-campaign-goals-work';
import HowCampaignsWork from '@common/sideInfoSheets/how-campaigns-work';
import LetUsChooseYourImage from '@common/sideInfoSheets/let-us-choose-your-image';
import PartnerAccountInfo from '@common/sideInfoSheets/partner-account-info';
import PaymentProfileInfo from '@common/sideInfoSheets/payment-profile-info';
import { SidePanel } from '@components/campaignManagement/campaignWizard/side-panel';
import useClickOutside from '@hooks/use-click-outside';
import { useLocalization } from '@shared-ui/localization-context';
import useMeasure from 'react-use-measure';
import { LayoutContextProvider } from 'src/context/layout-context';
import { useSideInfoSheetContext } from 'src/context/side-info-sheet-context';
import { UitkPrimaryButton } from 'uitk-react-button';
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkScrim, UitkScrimType } from 'uitk-react-scrim';
import { UitkSheet, UitkSheetContent, UitkSheetFooter, UitkSheetTransition } from 'uitk-react-sheet';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkToolbar } from 'uitk-react-toolbar';

interface IWizardLayoutProps {
  sideNav: React.ReactNode;
  main: React.ReactNode;
  aside?: React.ReactNode;
  footer?: React.ReactNode;
  hasPageTitle: boolean;
}

const WizardLayout: React.FC<IWizardLayoutProps> = ({ sideNav, main, aside, footer, hasPageTitle }) => {
  const [mainContainerRef, bounds] = useMeasure();
  const { showSideSheet } = useSideInfoSheetContext();

  let mainClass = 'wizard-main';
  if (!footer) {
    mainClass += ' full-height';
  }

  const collapse = bounds.width < 991;
  return (
    <LayoutContextProvider bounds={bounds}>
      {showSideSheet && (
        <UitkScrim style={{ cursor: 'none' }} className="side-info-sheet-scrim" type={UitkScrimType.OVERLAY} />
      )}
      <SideInfoSheet />
      <GlobalNavigationSiteHeader>
        <div className="top-nav-bar wizard-nav-bar" />
      </GlobalNavigationSiteHeader>
      <div className="nav-container nav-open">
        {sideNav}
        <main className={mainClass} ref={mainContainerRef}>
          <Banner />
          <UitkLayoutGrid columns={collapse ? ['3fr'] : ['2fr', '1fr']} alignContent="start" style={{ maxWidth: 1200 }}>
            <UitkLayoutGridItem>
              <div style={{ padding: '1.5rem', paddingRight: collapse ? '1.5rem' : '0.75rem' }}>{main}</div>
            </UitkLayoutGridItem>
            {aside && <SidePanel collapse={collapse} content={aside} marginTop={hasPageTitle ? '3rem' : ''} />}
          </UitkLayoutGrid>
          {footer && footer}
        </main>
      </div>
    </LayoutContextProvider>
  );
};

export default WizardLayout;

// Eventually the content of each sheet type should come from singular graphql query
// And each type will be a fragment
const SideInfoSheet = () => {
  const { formatText } = useLocalization();
  const ref = React.useRef();
  const { showSideSheet, setShowSideSheet, sideSheetTriggerRef, sheetName, setSheetName } = useSideInfoSheetContext();
  useClickOutside(ref, () => {
    if (showSideSheet) {
      setShowSideSheet(false);
      setSheetName(null);
    }
  });

  let sheetContent = null;
  let sheetDisplay = null;
  switch (sheetName) {
    case 'HOW_CAMPAIGNS_WORK':
      sheetContent = <HowCampaignsWork />;
      sheetDisplay = 'How ad campaigns work';
      break;
    case 'HOW_AD_GROUPS_WORK':
      sheetContent = <HowAdGroupsWork />;
      break;
    case 'HOW_BUDGETING_WORKS':
      sheetContent = <HowBudgetingWorks />;
      sheetDisplay = 'How does budgeting work?';
      break;
    case 'HOW_CAMPAIGN_GOALS_WORK':
      sheetContent = <HowCampaignGoalsWork />;
      sheetDisplay = 'About your campaign goal';
      break;
    case 'LET_US_CHOOSE_YOUR_IMAGE':
      sheetContent = <LetUsChooseYourImage />;
      sheetDisplay = 'Let us choose your image';
      break;
    case 'PARTNER_ACCOUNT_INFO':
      sheetContent = <PartnerAccountInfo />;
      sheetDisplay = 'Partner account';
      break;
    case 'PAYMENT_PROFILE_INFO':
      sheetContent = <PaymentProfileInfo />;
      sheetDisplay = 'Payment profile';
      break;
    default:
      sheetContent = null;
  }

  if (!showSideSheet || !sheetName || !sheetContent) {
    return null;
  }

  return (
    <>
      <div ref={ref}>
        <UitkSheetTransition isVisible={showSideSheet}>
          <UitkSheet isVisible={showSideSheet} type="side" triggerRef={sideSheetTriggerRef}>
            <UitkToolbar
              navigationContent={{
                onClick: () => setShowSideSheet(false),
                navIconLabel: 'Close',
              }}
              toolbarTitle={sheetDisplay}
            />
            <UitkSheetContent>{sheetContent}</UitkSheetContent>
            <UitkSheetFooter>
              <UitkSpacing margin={{ block: 'eight' }} padding={{ inline: 'eight' }}>
                <UitkLayoutFlex space="twelve" justifyContent="center">
                  <UitkLayoutFlexItem grow={1}>
                    <UitkPrimaryButton onClick={() => setShowSideSheet(false)}>
                      {formatText('wizardLayout.sideInfoSheetCloseButtonText')}
                    </UitkPrimaryButton>
                  </UitkLayoutFlexItem>
                </UitkLayoutFlex>
              </UitkSpacing>
            </UitkSheetFooter>
          </UitkSheet>
        </UitkSheetTransition>
      </div>
    </>
  );
};
