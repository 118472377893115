import { getUserInfo } from '@server/identity-utils';
import { UserStore } from '@stores/user-store';
import { ADMIN_ROLES, PARTNER_ROLES } from '@utils/controller-utils';
import { getTraceId } from '@utils/trace-utils';
import { CodeSplit, Controller, FetchOptions, FetchPageOptions } from 'bernie-core';
import { HttpStatus, PageData } from 'bernie-http';
import { Localization } from 'bernie-l10n';
import { SystemEventLevel } from 'bernie-logger';
import { ContextStore } from 'bernie-plugin-mobx';
import { serializeError } from 'serialize-error';
import { ERROR } from 'src/constants';

export abstract class AuthController implements Controller {
  public abstract pageTitle: string;

  public abstract bundles: string[];

  public abstract component: CodeSplit;

  public abstract exact: boolean;

  // Important, used to get correct controller from PageStore
  public abstract pageId: string;

  public abstract path: string;

  public abstract routeName: string;

  // If page is accessible for roles
  public abstract accessRoles: (ADMIN_ROLES | PARTNER_ROLES)[];

  // If page is readonly for roles
  public readOnlyAccessRoles: (ADMIN_ROLES | PARTNER_ROLES)[] = [];

  // Which navIcon to show in side bar
  // Absence indicates not shown in side bar
  public navIcon: string;

  public isPartnerProtected = true;

  public isAuthenticated = true;

  // Only show to external partners
  public isPartnerView: boolean = false;

  // Only show to internal SC partner
  public isAdminView: boolean = false;

  public isSandboxEnabled = false;

  public fetchPageData(options?: FetchPageOptions): Promise<PageData> {
    return Promise.resolve({
      title: this.getPageTitle(options?.context?.locale || 'en_US'),
      pageId: this.pageId,
      pageName: this.pageId,
    }) as Promise<PageData>;
  }

  public async fetch(options: FetchOptions | undefined) {
    if (typeof options === 'object' && Object.keys(options).length > 0) {
      const { stores, isServer } = options;
      // console.log('inside fetch of auth controller');
      if (isServer) {
        const userStore: UserStore = stores?.get<UserStore>('user');
        let userInfo = null;
        try {
          userInfo = await getUserInfo(options.request);
        } catch (error) {
          const traceId = getTraceId(options.request);
          options.request?.log([ERROR, 'AUTH_CONTROLLER', 'advertiser-portal-pwa.error.getUserInfo', traceId], {
            level: SystemEventLevel.CRITICAL,
            error: serializeError(error),
          });
        }
        const storePromises: Promise<unknown>[] = [];
        if (userInfo) {
          storePromises.push(
            userStore
              .setUserInfo({ request: options.request, userInfo })
              .then(() => Promise.resolve({ status: HttpStatus.OK })),
          );
        }
        await Promise.all(storePromises);
      } else {
        const context = stores?.get<ContextStore>('context');
        document.title = this.getPageTitle(context?.locale || 'en_US');
        return Promise.resolve({ status: HttpStatus.OK });
      }
    }
    return Promise.resolve({ status: HttpStatus.OK });
  }

  private getPageTitle(locale: string): string {
    const localization = new Localization(locale);
    if (this.pageTitle) {
      return localization.formatText(this.pageTitle);
    }

    return 'Advertiser Portal';
  }
}
