import * as React from 'react';

import { useLocalization } from '@shared-ui/localization-context';
import { UitkBadgeStandard } from 'uitk-react-badge';
import { UitkCard } from 'uitk-react-cards';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';

const HowCampaignGoalsWork = () => {
  const { formatText } = useLocalization();

  return (
    <UitkLayoutFlex direction="column" space="four">
      <UitkText>{formatText('common.sideInfoSheets.howCampaignGoalsWorkParagraph1')}</UitkText>
      <UitkText>{formatText('common.sideInfoSheets.howCampaignGoalsWorkParagraph2')}</UitkText>
      <UitkCard border={true} padded>
        <UitkLayoutFlex direction="column">
          <UitkLayoutFlexItem>
            <UitkSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <UitkHeading>{formatText('common.sideInfoSheets.howCampaignGoalsWorkMaximizeClicksHeading')}</UitkHeading>
            </UitkSpacing>
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem>
            <UitkSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <UitkText>{formatText('common.sideInfoSheets.howCampaignGoalsWorkMaximizeClicksDescription')}</UitkText>
            </UitkSpacing>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkCard>
      <UitkCard border={true} padded>
        <UitkLayoutFlex direction="column" justifyContent="space-between">
          <UitkLayoutFlex justifyContent="space-between">
            <UitkSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <UitkHeading>{formatText('common.sideInfoSheets.howCampaignGoalsWorkMaximizeRoasHeading')}</UitkHeading>
            </UitkSpacing>
            <UitkBadgeStandard theme="info">
              {formatText('common.sideInfoSheets.howCampaignGoalsWorkComingSoonBadgeText')}
            </UitkBadgeStandard>
          </UitkLayoutFlex>
          <UitkLayoutFlexItem>
            <UitkSpacing margin={{ blockstart: 'two' }} padding={{ inline: 'four' }}>
              <UitkText theme="secondary">
                {formatText('common.sideInfoSheets.howCampaignGoalsWorkMaximizeRoasDescription')}
              </UitkText>
            </UitkSpacing>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkCard>
    </UitkLayoutFlex>
  );
};

export default HowCampaignGoalsWork;
