import React, { useEffect, useRef } from 'react';

import useClickOutside from '@hooks/use-click-outside';
import { useBexApi } from '@shared-ui/bex-api-context';
import { useLocalization } from '@shared-ui/localization-context';
import moment from 'moment';
import { UitkDateSelector, UitkDateSelectorProps } from 'uitk-react-date-selector';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { PositionType, UitkMenu, UitkMenuContainer, UitkMenuList, UitkMenuTrigger } from 'uitk-react-menu';
import { UitkSheetTransition, UitkSheet, UitkSheetContent } from 'uitk-react-sheet';

type IDatePickerMenuProps = Partial<UitkDateSelectorProps> & {
  menuItems: DatePickerOption[];
  defaultItem: string;
  handleDateSelect: (startDate: string, endDate: string) => void;
  menuTitle: string;
  dateSelectorProps?: Partial<UitkDateSelectorProps>;
};

interface DatePickerOption {
  label: string;
  value: string;
}

const formatOptions: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };

export const DatePickerMenu: React.FC<IDatePickerMenuProps> = (props) => {
  const menuItems = useRef(props.menuItems);
  if (!menuItems?.current) {
    menuItems.current = props.menuItems;
  }

  const { context } = useBexApi();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState('');
  const [customDateRangeOpen, setCustomDateRangeOpen] = React.useState(false);
  const [customDates, setCustomDates] = React.useState<string[]>([]);
  const [customDisplayDates, setCustomDisplayDates] = React.useState<string[]>([]);
  const [display, setDisplay] = React.useState<'composable' | 'fullscreenDialog'>('composable');
  const { formatDateString, formatText } = useLocalization();

  const triggerRef = useRef<HTMLSelectElement>();
  const containerRef = useRef<HTMLDivElement>();

  useClickOutside(
    containerRef,
    () => {
      setMenuOpen(false);
      setCustomDateRangeOpen(false);
      setCustomDisplayDates([]);
    },
    true,
  );

  useEffect(() => {
    const handleResize = () => {
      setMenuOpen(false);
      setCustomDateRangeOpen(false);
      setCustomDisplayDates([]);
      if (window.innerWidth < 800) {
        setDisplay('fullscreenDialog');
      } else {
        setDisplay('composable');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!selectedItem) {
      setSelectedItem(props.defaultItem ?? menuItems.current[0].value);
    }
  }, [props]);

  const onDateRangeChange = (customStartDate, customEndDate) => {
    setCustomDisplayDates([customStartDate, customEndDate]);
  };

  const updateCustomDates = () => {
    const customStartDate = customDisplayDates[0];
    const customEndDate = customDisplayDates[1];
    if (
      customStartDate != undefined &&
      customEndDate != undefined &&
      moment(customStartDate).isValid() &&
      moment(customEndDate).isValid()
    ) {
      setSelectedItem('custom');
      setMenuOpen(false);
      setCustomDateRangeOpen(false);
      setCustomDates([customStartDate, customEndDate]);
      setCustomDisplayDates([]);
      props.handleDateSelect(customStartDate, customEndDate);
    }
  };

  const handleMenuItemClick = (value) => {
    if (value === 'custom') {
      setCustomDateRangeOpen(true);
    } else {
      setSelectedItem(value);
      setCustomDateRangeOpen(false);
      setCustomDates([]);
      setCustomDisplayDates([]);
      props.handleDateSelect(value, moment().format('YYYY-MM-DD'));
    }
    setMenuOpen(false);
  };

  if (!menuItems?.current) {
    return null;
  }

  return (
    <div ref={containerRef}>
      <UitkMenu id="date-picker-menu" isOpen={menuOpen}>
        <UitkMenuTrigger>
          <div style={{ position: 'relative' }}>
            <div className="uitk-field uitk-field-select-field has-floatedLabel-label has-placeholder has-icon">
              <label className="uitk-field-label">{props.menuTitle}</label>
              <UitkIcon className={'uitk-field-icon'} name="calendar" />
              <button
                className={'uitk-fake-input'}
                onClick={() => {
                  setMenuOpen((prev) => !prev);
                }}
              >
                {selectedItem === 'custom'
                  ? `${formatDateString(customDates[0], { skeleton: 'yMMMd' })} - ${formatDateString(customDates[1], {
                      skeleton: 'yMMMd',
                    })}`
                  : menuItems.current.find((a) => a.value === selectedItem)?.label}
              </button>
              <UitkIcon name="expand_more" className={'uitk-field-select-icon'} size={UitkIconSize.SMALL} />
            </div>
            <div style={{ position: 'absolute', top: '0' }} className="reporting-menu">
              <UitkSheetTransition isVisible={!!customDateRangeOpen}>
                <UitkSheet
                  isVisible={!!customDateRangeOpen}
                  type="popover"
                  triggerRef={triggerRef}
                  className="reporting-menu"
                  width={700}
                >
                  <UitkSheetContent padded={false}>
                    <UitkDateSelector
                      dialogId="custom-dates"
                      initialOpenState={true}
                      onDateRangeChange={onDateRangeChange}
                      startDate={customDisplayDates[0]}
                      endDate={customDisplayDates[1]}
                      dateFormatOptions={formatOptions}
                      popoverWidth={600}
                      displayType={display}
                      onSubmit={updateCustomDates}
                      handleShape={'circle'}
                      locale={context.locale.replace('_', '-')}
                      enableSameDayDateRange={true}
                      enablePastDates={true}
                      inputIcon1="calendar"
                      useSingleTrigger={true}
                      submitButtonLabel={formatText('common.datePickerMenuDoneButtonText')}
                      allowClearDates={false}
                      numColumns={2}
                      numMonthsToDisplay={2}
                      {...props.dateSelectorProps}
                    ></UitkDateSelector>
                  </UitkSheetContent>
                </UitkSheet>
              </UitkSheetTransition>
            </div>
          </div>
        </UitkMenuTrigger>
        <UitkMenuContainer position={PositionType.LEFT} className="reporting-menu">
          <UitkMenuList
            items={[
              ...menuItems.current.map((a) => ({
                className: 'uitk-list-item',
                label: a.label,
                selected: a.value === selectedItem,
                onClick: () => {
                  handleMenuItemClick(a.value);
                },
              })),
            ]}
          />
        </UitkMenuContainer>
      </UitkMenu>
    </div>
  );
};
