import { ADMIN_ROLES } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

import { AuthController } from '../auth-controller';

export class CampaignSearchController extends AuthController {
  public pageId = 'campaignSearch';
  public path = '/admn/campaignSearch';
  public routeName = 'campaignSearch';
  public bundles = [];
  public pageTitle = 'controllers.campaignSearch';
  public isAdminView = true;
  public accessRoles = [
    ADMIN_ROLES.INTERNAL_ADMIN,
    ADMIN_ROLES.INTERNAL_TECH_SUPPORT,
    ADMIN_ROLES.INTERNAL_ADMIN_VIEWER,
    ADMIN_ROLES.INTERNAL_COLLECTIONS_MANAGER,
    ADMIN_ROLES.INTERNAL_REV_OPS,
    ADMIN_ROLES.INTERNAL_SALES,
  ];
  public navIcon = 'search';

  public component = codeSplit(
    () => import(/* webpackChunkName: "campaignSearch" */ '../../views/admin/campaign-search'),
  );
  public exact = true;
}
