import { SerializedData } from 'bernie-core';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { Store } from 'bernie-plugin-mobx';
import { makeObservable, observable } from 'mobx';

interface IControlPlaneGraphConfig {
  url: string;
}

interface IAppUrl {
  url: string;
}

export interface Config {
  controlPlaneGraph: IControlPlaneGraphConfig;
  appUrl: IAppUrl;
  deployedEnv: string;
  authAppSignoutUrl: string;
  defaultClientId;
  sponsoredContentPartnerAccountId: string;
  userProfileUrl: string;
}

export class ConfigStore extends Store {
  @observable
  appConfig?: Config;

  constructor(logger: Logger = NOOP_LOGGER, appConfig?: Config) {
    super({}, logger);
    this.appConfig = appConfig;
    makeObservable(this);
  }

  hydrate(data: SerializedData): void {
    this.appConfig = data.appConfig;
  }

  public toJSON(ignoreAdditionalKeys: string[] | string = []): SerializedData {
    const json = super.toJSON(ignoreAdditionalKeys);
    delete json.appConfig?.secret;
    delete json.appConfig?.credential;
    return json;
  }
}
