import { AuthController } from '@controllers/auth-controller';
import { SELECT_ACCOUNT } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class SelectAccountController extends AuthController {
  public pageId = SELECT_ACCOUNT;
  public path = '/select-account';
  public routeName = 'SelectAccount';
  public bundles = [];
  public pageTitle = 'controllers.selectAccount';
  public isPartnerProtected: boolean = false;
  public component = codeSplit(() => import(/* webpackChunkName: "selectAccount" */ '../../views/select-account'));
  public exact = true;
  public isPartnerView = true;
  public isAdminView = true;
  public accessRoles = [];
  public navIcon = '';
}
