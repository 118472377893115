import { ILocalization } from 'bernie-l10n';
import moment from 'moment';
import { DateInput } from 'src/common/__generated__/api/types';

export const getCurrentDateTimeUTC = (): string => {
  const now = new Date();
  return now.toISOString();
};

export const prettyPrintLastUpdated = (lastUpdatedUtcString: string, l10n: ILocalization): string => {
  const dateTime = new Date(lastUpdatedUtcString);

  // convert utc time to localtime since globalize always converts to local
  dateTime.setMinutes(dateTime.getMinutes() + dateTime.getTimezoneOffset());

  if (isNaN(dateTime.getTime())) {
    return '';
  }

  const formatedDate = l10n.formatDate(dateTime, { date: 'medium' });
  const formatedTime = l10n.formatDate(dateTime, { time: 'short' });

  return l10n.formatText('common.lastUpdated', formatedDate, formatedTime);
};

export const formatDate = (date): DateInput => {
  const utcDate = moment(date).utc();
  return {
    day: Number(utcDate.format('DD')),
    month: Number(utcDate.format('MM')),
    year: Number(utcDate.format('YYYY')),
  };
};

export const formatReportingDate = (date): DateInput => {
  const utcDay = new Date().getUTCDate();
  const utcMonth = new Date().getUTCMonth();
  const utcYear = new Date().getUTCFullYear();
  const utcToday = new Date(utcYear, utcMonth, utcDay);
  let formattedDate = moment(date);
  if (formattedDate.isAfter(moment(utcToday))) {
    formattedDate = moment(utcToday);
  }
  return {
    day: Number(formattedDate.format('DD')),
    month: Number(formattedDate.format('MM')),
    year: Number(formattedDate.format('YYYY')),
  };
};

export const formatDateToLocal = (utcString) => {
  const date = new Date(utcString);
  if (isNaN(date.getTime())) {
    return '';
  }
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC',
  };
  let localDateString = date.toLocaleString(undefined, options);
  const commaCount = (localDateString.match(/,/g) || []).length;
  if (commaCount > 1) {
    localDateString = localDateString.replace(/,([^,]*)$/, ' at$1');
  }
  localDateString = localDateString.replace(/(\d{1,2}:\d{2}) ([AP]M)/, '$1$2');
  return isNaN(date.getTime()) ? '' : localDateString + ' UTC.';
};
